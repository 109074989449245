// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const buildUrl = (url, params = {}) => {
    const searchParams = new URLSearchParams();
    Object.entries(params)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => searchParams.append(key, `${value}`));
    const searchString = searchParams.toString();
    return `${url}${searchString ? `?${searchString}` : ''}`;
};
