// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const readLocalStorageValue = (key, defaultValue) => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : defaultValue;
    } catch {
        return defaultValue;
    }
};
