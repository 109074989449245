// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

let dropdowns = [];

const closeAllDropdowns = event => {
    dropdowns.forEach(dropdown => {
        if (dropdown.isOpened(event)) {
            dropdown.close(event);
        }
    });
};

/**
 * @class DropdownManager
 * @singleton
 */
export const DropdownManager = {
    /**
     * Register dropdown component.
     * @param {Element} toggler
     * @param {Function} isOpened
     * @param {Function} open
     * @param {Function} close
     * @param {Function} [beforeClick]
     * @returns {Object}
     */
    register(toggler, isOpened, open, close, beforeClick) {
        const dropdown = {
            toggler,
            isOpened,
            open,
            close,
            handler(event) {
                if (beforeClick && !beforeClick(event)) {
                    return;
                }
                event.stopPropagation();
                const oldIsOpened = isOpened(event);
                closeAllDropdowns(event);
                if (!oldIsOpened) {
                    open(event);
                }
            },
        };

        dropdowns.push(dropdown);
        dropdown.toggler.addEventListener('click', dropdown.handler);

        if (dropdowns.length > 0) {
            document.body.addEventListener('click', closeAllDropdowns);
        }

        return dropdown;
    },

    /**
     * Un register dropdown component.
     * @param {Object} dropdown
     */
    unregister(dropdown) {
        dropdown.toggler.removeEventListener('click', dropdown.handler);
        dropdowns = dropdowns.filter(item => item !== dropdown);

        if (dropdowns.length === 0) {
            document.body.removeEventListener('click', closeAllDropdowns);
        }
    },
};
