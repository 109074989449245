// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Component } from './component';
import prepareUrl from './prepareUrl';
import render from './render';

import './dynamic-popup-hint.less';

/**
 * @class DynamicPopupHint
 * @singleton
 */
export const DynamicPopupHint = {
    windowId: 'ajaxTooltipWindow',
    delay: 0.55,
    _isInitialized: false,
    _currentTooltip: null,

    _init(cls) {
        if (this._isInitialized) {
            return;
        }

        this._cls = cls;
        this._initWindowElement();
        this._initWindowEvents();
        this._isInitialized = true;
    },

    _initWindowElement() {
        render(document.body, `<div id="${this.windowId}" class="${this._cls}" style="display: none; z-index: 10000"></div>`, 'top');

        const element = document.getElementById(this.windowId);
        element.innerHTML = (
            '<table cellspacing="0" class="popup-wrapper"><tbody><tr><td class="popup-container">' +
                '<div class="c1"><div class="c2"><div class="c3"><div class="c4"><div class="c5">' +
                    '<div class="popup-heading">' +
                        '<div class="heading-area">' +
                            '<span class="arrow"></span>' +
                            `<span class="close" id="${this.windowId}-close"></span>` +
                            `<h4><span id="${this.windowId}-title"></span></h4>` +
                        '</div>' +
                    '</div>' +
                    '<div class="popup-content">' +
                        `<div id="${this.windowId}-content" class="popup-content-area"></div>` +
                    '</div>' +
                '</div></div></div></div></div>' +
            '</td></tr></tbody></table>'
        );
        // TODO Need remove this hack when all popup boxes will use DynamicPopupHint component and hardcoded offset will be removed in css
        element.style.marginTop = 0;
    },

    _initWindowEvents() {
        document.getElementById(this.windowId).addEventListener('mouseover', this._onWindowMouseOver.bind(this));
        document.getElementById(this.windowId).addEventListener('mouseout', this._onWindowMouseOut.bind(this));
        document.getElementById(`${this.windowId}-close`).addEventListener('click', this._onCloseClick.bind(this));
        document.body.addEventListener('click', this._onCloseClick.bind(this));
        document.getElementById(this.windowId).addEventListener('click', event => {
            event.stopPropagation();
        });
    },

    _onWindowMouseOver() {
        this._showWindow();
    },

    _onWindowMouseOut() {
        if (this._currentTooltip.getPlacement() === '') {
            document.getElementById(this.windowId).style.display = 'none';
        }
    },

    _onCloseClick() {
        document.getElementById(this.windowId).style.display = 'none';
    },

    set(tooltip) {
        this._init(tooltip.getCls());
        const title = tooltip.getTitle();
        const titleElement = document.getElementById(`${this.windowId}-title`);
        if (false === title) {
            document.getElementById(`${this.windowId}-title`).innerHTML = '';
            titleElement.closest('.popup-heading').style.display = 'none';
        } else {
            document.getElementById(`${this.windowId}-title`).innerHTML = title;
            titleElement.closest('.popup-heading').style.display = '';
        }
        ['left', 'right', 'top', 'bottom'].forEach(className => {
            document.getElementById(this.windowId).classList.remove(className);
        });
        document.getElementById(this.windowId).classList.add(tooltip.getPlacement());
        document.getElementById(this.windowId)
            .querySelector('.heading-area .arrow').style.display = ['left', 'right', 'bottom'].indexOf(tooltip.getPlacement()) !== -1 ? '' : 'none';
        document.getElementById(this.windowId).classList[tooltip.getScrollable() ? 'add' : 'remove']('popup-box-scrollable');
        this._currentTooltip = tooltip;
        this.updateContent(tooltip);
        this._showWindow(tooltip);
    },

    isSet(tooltip) {
        const element = document.getElementById(this.windowId);
        return element && element.style.display !== 'none' && this._currentTooltip === tooltip;
    },

    unset() {
        const element = document.getElementById(this.windowId);
        if (element) {
            element.style.display = 'none';
        }
    },

    updateContent(tooltip) {
        if (this._currentTooltip === tooltip) {
            render(document.getElementById(`${this.windowId}-content`), [
                tooltip.getPlacement() === 'top' ? '<span class="arrow"></span>' : '',
                tooltip.getContent(),
            ], 'inner');
        }
        this._updatePosition();
    },

    _showWindow() {
        document.getElementById(this.windowId).style.display = '';
        this._updatePosition();
    },

    _updatePosition() {
        const element = document.getElementById(this.windowId);
        const elementWidth = Element.getWidth(element);
        const elementHeight = Element.getHeight(element);
        const headingElementHeight = Element.getHeight(element.querySelector('.popup-heading'));
        const tooltipElement = this._currentTooltip.getTargetElement();
        const tooltipElementHeight = Element.getHeight(tooltipElement);
        const tooltipElementWidth = Element.getWidth(tooltipElement);
        let offsetLeft = 0;
        let offsetTop = 0;
        const elementRight = Element.cumulativeOffset(tooltipElement).left + elementWidth;
        const viewportRight = document.viewport.getWidth() + document.viewport.getScrollOffsets().left;

        switch (this._currentTooltip.getPlacement()) {
            case 'top':
                offsetTop -= elementHeight;
                offsetLeft -= (elementWidth - tooltipElementWidth) / 2;
                break;
            case 'bottom':
                offsetTop += tooltipElementHeight;
                offsetLeft -= (elementWidth - tooltipElementWidth) / 2;
                break;
            case 'left':
                offsetTop -= (headingElementHeight - tooltipElementHeight) / 2;
                offsetLeft -= elementWidth;
                break;
            case 'right':
                offsetTop -= (headingElementHeight - tooltipElementHeight) / 2;
                offsetLeft += tooltipElementWidth;
                break;
        }
        if (elementRight + offsetLeft > viewportRight) {
            offsetLeft -= elementRight + offsetLeft - viewportRight + 10;
        }

        Element.clonePosition(element, tooltipElement, {
            offsetLeft,
            offsetTop,
            setWidth: false,
            setHeight: false,
        });
    },
};

DynamicPopupHint.Instance = class Instance extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._targetElement = this._getConfigParam('target', '');
        if (typeof this._targetElement === 'string') {
            this._targetElement = document.getElementById(this._targetElement);
        }
        this._title = this._getConfigParam('title', '');
        this._waitMsg = this._getConfigParam('waitMsg', '');
        this._url = this._getConfigParam('url', '');
        this._cls = this._getConfigParam('cls', 'popup-box');
        this._scrollable = this._getConfigParam('scrollable', true);
        this._placement = this._getConfigParam('placement', '');

        this._content = this._getConfigParam('content', `<div class="ajax-loading">${this._waitMsg}</div>`);
        this._contentLoaded = false;
        this._delayedExecuter = null;
    }

    _initComponentElement() {
        this._initTargetEvents();
    }

    _initTargetEvents() {
        if (this._placement !== '') {
            this._targetElement.addEventListener('click', event => {
                event.stopPropagation();
                event.preventDefault();
                if (DynamicPopupHint.isSet(this)) {
                    DynamicPopupHint.unset();
                } else {
                    DynamicPopupHint.set(this);
                }
            });
        } else {
            this._targetElement.addEventListener('mouseover', this.set.bind(this));
            this._targetElement.addEventListener('mouseout', this.unset.bind(this));
        }
    }

    _loadContent() {
        new Ajax.Request(prepareUrl(this._url), {
            method: 'get',
            onSuccess: transport => {
                if ('' === transport.responseText) {
                    return;
                }

                this._content = transport.responseText;
                DynamicPopupHint.updateContent(this);
            },
        });

        this._contentLoaded = true;
    }

    setContent(content) {
        this._contentLoaded = true;
        this._content = content;
    }

    set() {
        this._delayedExecuter = new PeriodicalExecuter(pe => {
            DynamicPopupHint.set(this);
            pe.stop();
            this._delayedExecuter = null;
        }, DynamicPopupHint.delay);
    }

    unset() {
        if (this._delayedExecuter) {
            this._delayedExecuter.stop();
            this._delayedExecuter = null;
        }
    }

    getTitle() {
        return this._title;
    }

    getContent() {
        if (!this._contentLoaded && this._url) {
            this._loadContent();
        }

        return this._content;
    }

    getTargetElement() {
        return this._targetElement;
    }

    getCls() {
        return this._cls;
    }

    getScrollable() {
        return this._scrollable;
    }

    getPlacement() {
        return this._placement;
    }
};
