// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { PopupForm } from './popup-form';
import { DynamicPopupHint } from './dynamic-popup-hint';
import { List } from './list';
import { Panel } from './panel';
import { getComponent } from './component';
import { redirect } from './form-redirect';
import Locale from './Locale';
import addStatusMessage from './addStatusMessage';
import prepareUrl from './prepareUrl';
import escapeHtml from './escapeHtml';

export class ReporterPopup extends PopupForm {
    _initConfiguration(config) {
        super._initConfiguration({
            singleRowButtons: true,
            ...config,
        });

        this._actionCollectId = `${this._id}-action-collect`;
        this._actionToSupportId = `${this._id}-action-to-support`;
        this._sendButtonId = 'btn-send';
        this._cancelButtonId = 'btn-cancel';
        this._supportUrl = this._getConfigParam('supportUrl', '');
    }

    render() {
        super.render();

        this.setBoxType('form-box');
        this.setTitle(this.lmsg('title'));

        this.addRightButton(this.lmsg('buttonCollect'), this._onSendClick, true, true, { id: this._sendButtonId });
        this.addRightButton(Locale.getSection('components.buttons').lmsg('cancel'), this._onCancelClick, false, false, { id: this._cancelButtonId });
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.querySelector('div.content-area').innerHTML = (
            '<div class="form-row">' +
                '<div class="single-row">' +
                    '<div class="field-value">' +
                        '<label>' +
                            `<input class="radio" type="radio" name="action" id="${this._actionCollectId}" checked="checked"> ` +
                            `${this.lmsg('actionCollect')}${this._getTooltip()}` +
                        '</label>' +
                    '</div>' +
                    '<div class="field-value">' +
                        `<label><input class="radio" type="radio" name="action" id="${this._actionToSupportId}"> ${this.lmsg('actionToSupport')}</label>` +
                    '</div>' +
                '</div>' +
            '</div>' +
            `<a href="#" class="toggler js-show-previous-reports">${this.lmsg('showPreviousReports')}</a>` +
            `<a href="#" class="toggler hidden js-hide-previous-reports">${this.lmsg('hidePreviousReports')}</a>` +
            `<div id="js-reports"><span class="ajax-loading hidden">${this.lmsg('pleaseWait')}</span></div>`
        );
    }

    _onSupportClick(close) {
        window.open(this._supportUrl, '_blank');
        this._disableSendButton(this.lmsg('buttonWait'));
        new Ajax.Request(
            prepareUrl('/admin/report/report-usage/'), {
                method: 'post',
                onException: this._onException.bind(this),
                onSuccess: () => {
                    close ? this.hide() : this._enableSendButton(this.lmsg('buttonToSupport'));
                },
            },
        );
    }

    _onCollectClick() {
        this._disableSendButton();
        DynamicPopupHint.unset();
        document.getElementById(this._contentAreaId).innerHTML = `<p class="text-center"><span class="ajax-loading">${this.lmsg('collecting')}${this._getTooltip()}</span></p>`;
        this._addTooltips();
        new Ajax.Request(
            prepareUrl('/admin/report/collect/'), {
                method: 'post',
                onException: this._onException.bind(this),
                onSuccess: response => {
                    const data = JSON.parse(response.responseText);
                    if (this._checkResponseStatus(data)) {
                        DynamicPopupHint.unset();
                        const link = `<a href="${prepareUrl(`/admin/report/download/file/${encodeURIComponent(data.file)}`)}">${this.lmsg('downloadLink')}</a>`;
                        document.getElementById(this._contentAreaId).innerHTML = (
                            `<p>${this.lmsg('collectedDescription')}</p>` +
                            `<p>${this.lmsg('collectedDownload', { link })}</p>` +
                            `<p>${this.lmsg('collectedConsent')}</p>`
                        );
                        this.file = data.file;
                        this._enableSendButton(this.lmsg('buttonSend'));
                    }
                },
            },
        );
    }

    _onSendClick() {
        const collectRadio = document.getElementById(this._actionCollectId);
        if (collectRadio && collectRadio.checked) {
            this._onCollectClick();
            return;
        }

        if (collectRadio && !collectRadio.checked) {
            this._onSupportClick(true);
            return;
        }

        if (!this.file) {
            this._onSupportClick(false);
            return;
        }

        this._disableSendButton(this.lmsg('buttonSending'));
        new Ajax.Request(
            prepareUrl('/admin/report/send/'), {
                method: 'post',
                parameters: { file: this.file },
                onException: this._onException.bind(this),
                onSuccess: response => {
                    const data = JSON.parse(response.responseText);
                    if (this._checkResponseStatus(data)) {
                        delete this.file;
                        document.getElementById(this._contentAreaId).innerHTML = `<p>${this.lmsg('sentInfo', { id: escapeHtml(data.reportId) })}</p>`;
                        this._enableSendButton(this.lmsg('buttonToSupport'));
                    }
                },
            },
        );
    }

    _onCancelClick() {
        this.hide();
    }

    _onShowReportsClick(e) {
        e.preventDefault();
        this._componentElement.querySelector('.js-show-previous-reports').classList.add('hidden');
        if (getComponent('reports-list') && this._componentElement.querySelector('.list-box')) {
            this._componentElement.querySelector('.list-box').classList.remove('hidden');
            this._componentElement.querySelector('.js-hide-previous-reports').classList.remove('hidden');
            return;
        }
        this._componentElement.querySelector('.ajax-loading').classList.remove('hidden');
        const dialogHeight = Element.getHeight(this._componentElement);
        const list = new List({
            id: 'reports-list',
            dataUrl: prepareUrl('/admin/report/list-data'),
            pageable: false,
            columns: [
                {
                    header: this.lmsg('date'),
                    dataIndex: 'date',
                },
                {
                    header: this.lmsg('reportId'),
                    dataIndex: 'id',
                },
            ],
            onRedraw: () => {
                this._componentElement.querySelector('.js-hide-previous-reports').classList.remove('hidden');
                this._componentElement.querySelector('.ajax-loading').classList.add('hidden');
                const maxHeight = Math.max(document.viewport.getScrollOffsets().top + document.viewport.getHeight() -
                    (parseInt(Element.getStyle(this._componentElement, 'top')) * 1.5) - dialogHeight, 100);
                this._componentElement.querySelector('.list').style.maxHeight = `${parseInt(maxHeight)}px`;
            },
        });
        new Panel({
            cls: 'list-box',
            renderTo: 'js-reports',
            items: [
                list,
            ],
        });
    }

    _onHideReportsClick(e) {
        e.preventDefault();
        this._componentElement.querySelector('.list-box').classList.add('hidden');
        this._componentElement.querySelector('.js-show-previous-reports').classList.remove('hidden');
        this._componentElement.querySelector('.js-hide-previous-reports').classList.add('hidden');
    }

    _addEvents() {
        super._addEvents();

        document.getElementById(this._actionCollectId).addEventListener('click', () => {
            this._enableSendButton(this.lmsg('buttonCollect'));
        });
        document.getElementById(this._actionToSupportId).addEventListener('click', () => {
            this._enableSendButton(this.lmsg('buttonToSupport'));
        });
        this._componentElement.querySelector('.js-show-previous-reports').addEventListener('click', this._onShowReportsClick.bind(this));
        this._componentElement.querySelector('.js-hide-previous-reports').addEventListener('click', this._onHideReportsClick.bind(this));
    }

    _addTooltips() {
        super._addTooltips();

        new DynamicPopupHint.Instance({
            title: this.lmsg('hintTitle'),
            target: document.getElementById('tooltip'),
            placement: 'right',
            content: this.lmsg('hintText'),
        });
    }

    _getTooltip() {
        if (!this._tooltip) {
            this._tooltip = `&nbsp;<a class="nowrap" id="tooltip">${this.lmsg('hintLink')}</a>`;
        }
        return this._tooltip;
    }

    _disableSendButton(title) {
        const el = document.getElementById(this._sendButtonId);
        el.disabled = true;
        el.classList.add('disabled');
        if (title) {
            el.innerHTML = `<span class="wait">${title}</span>`;
        }
    }

    _enableSendButton(title) {
        const el = document.getElementById(this._sendButtonId);
        el.disabled = false;
        el.classList.remove('disabled');
        if (title) {
            el.innerHTML = title;
        }
    }

    _checkResponseStatus(response) {
        if (response.redirect) {
            redirect(response.redirect);
        }

        if (response.status && 'success' !== response.status) {
            (response.statusMessages || []).forEach(function (message) {
                addStatusMessage(message.status, message.content);
            });
            this.hide();
            return false;
        }
        return true;
    }

    _onException(transport, exception) {
        addStatusMessage('error', `Internal error: ${exception}`);
        this.hide();
    }
}
