// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const createHtmlElement = (tagName, attrs = {}) => {
    const el = document.createElement(tagName);

    Object.entries(attrs).forEach(([name, value]) => {
        if (value !== null && value !== false) {
            el.setAttribute(name, value);
        }
    });

    return el;
};
