// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { Text, List, ConsoleOutput } from '@plesk/ui-library';

const ExceptionDetails = ({ type, message, file, line, stackTrace = null, codeFragment = null }) => {
    const data = [{
        key: 'type',
        title: 'Type',
        value: type,
    }, {
        key: 'message',
        title: 'Message',
        value: message,
    }, {
        key: 'file',
        title: 'File',
        value: file,
    }, {
        key: 'line',
        title: 'Line',
        value: line,
    }, {
        key: 'stackTrace',
        title: 'Stack trace',
        // eslint-disable-next-line react/no-danger
        value: stackTrace && <ConsoleOutput wide>{stackTrace}</ConsoleOutput>,
    }, {
        key: 'codeFragment',
        title: 'Code fragment',
        value: codeFragment && (
            <ConsoleOutput wide>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: codeFragment }} />
            </ConsoleOutput>
        ),
    }].filter(({ value }) => value);

    if (!data.length) {
        return null;
    }

    return (
        <List
            columns={[{
                key: 'title',
                render(row) {
                    return <Text bold>{row.title}</Text>;
                },
            }, {
                key: 'value',
            }]}
            data={data}
        />
    );
};

ExceptionDetails.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    stackTrace: PropTypes.string,
    codeFragment: PropTypes.string,
};

export default ExceptionDetails;
