// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as constants from '../constants';
import SERVER_INFO_QUERY from 'queries/AdminHomeServerInfo.graphql';
import UPDATES_QUERY from 'queries/AdminHomeUpdates.graphql';
import OVERUSED_SUBSCRIPTIONS_QUERY from 'queries/AdminHomeOverusedSubscriptions.graphql';
import MY_SUBSCRIPTIONS_QUERY from 'queries/AdminHomeMySubscriptions.graphql';
import EXTENSIONS_QUERY from 'queries/AdminHomeExtensions.graphql';
import CHANGE_LOG_QUERY from 'queries/AdminHomeChangeLog.graphql';

export default {
    [constants.SERVER_INFO]: SERVER_INFO_QUERY,
    [constants.UPDATES]: UPDATES_QUERY,
    [constants.OVERUSED_SUBSCRIPTIONS]: OVERUSED_SUBSCRIPTIONS_QUERY,
    [constants.MY_SUBSCRIPTIONS]: MY_SUBSCRIPTIONS_QUERY,
    [constants.EXTENSIONS]: EXTENSIONS_QUERY,
    [constants.CHANGE_LOG]: CHANGE_LOG_QUERY,
};
