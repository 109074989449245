// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import prepareUrl from './prepareUrl';
import addUrlParams from './addUrlParams';
import render from './render';
import createElement from './createElement';
import { createHtmlElement } from 'helpers/createHtmlElement';
/**
 * Submit form.
 * @param {Element|String} element Form element or form child element or it id.
 * @param {Boolean} [noRedirect]
 * @member Jsw
 */
export const submit = (element, noRedirect) => {
    const form = (typeof element === 'string' ? document.getElementById(element) : element).closest('form');
    form.noRedirect = noRedirect;
    form.submit();
};

const isJavascriptProtocol = url => {
    try {
        return new URL(url).protocol === 'javascript:';
    } catch {
        // seems like a relative url;
        return false;
    }
};

/**
 * Go to url with get method.
 * @param {String|Object} action
 * @param {Object} [data]
 * @param {String} [target]
 * @member Jsw
 */
export const redirect = (action, data, target) => {
    let url = action;
    let method = 'get';
    if (Object.prototype.toString.call(action) === '[object Object]') {
        /* eslint-disable prefer-destructuring */
        method = action.method || method;
        data = action.data;
        target = action.target;
        url = action.url;
        /* eslint-enable prefer-destructuring */
    }
    if (isJavascriptProtocol(url)) {
        throw new Error(`The redirect to this url can not be performed: ${url}`);
    }
    url = prepareUrl(url);
    method = method.toLowerCase();
    if ('post' === method || (data && Object.keys(data).length) || target) {
        FormRedirect.redirect(url, method, data, target);
    } else {
        window.location.assign(url);
    }
};

/**
 * Go to url with post method.
 * @param {String} action
 * @param {Object} [data]
 * @param {String} [target]
 * @member Jsw
 */
export const redirectPost = (action, data, target) => {
    FormRedirect.redirect(prepareUrl(action), 'post', data, target);
};

/**
 * @class FormRedirect
 * @singleton
 */
export const FormRedirect = {
    /**
     * @param {String} action
     * @param {String} method
     * @param {Object} [data]
     * @param {String} [target]
     */
    redirect(action, method, data, target) {
        render(
            document.body,
            createElement(
                'form',
                {
                    method,
                    target,
                    // workaround for http://code.google.com/p/chromium/issues/detail?id=10671
                    action: addUrlParams(action, `_randomId=${Math.floor(1000000 * Math.random())}`),
                    onrender(form) {
                        setTimeout(() => {
                            form.submit();
                        }, 0);
                    },
                },
                data ? FormRedirect.subToElements(data) : null,
                document.getElementById('forgery_protection_token') ? createElement('input', {
                    type: 'hidden',
                    name: 'forgery_protection_token',
                    value: document.getElementById('forgery_protection_token').content,
                }) : null,
            ),
        );
    },

    /**
     * @param {String} value
     * @param {String} prefix
     * @returns {Element[]}
     */
    stringToElements(value, prefix) {
        return [createHtmlElement('input', {
            type: 'hidden',
            name: prefix,
            value,
        })];
    },

    /**
     * @param {Boolean} value
     * @param {String} prefix
     * @returns {Element[]}
     */
    booleanToElements(value, prefix) {
        return [createHtmlElement('input', {
            type: 'hidden',
            name: prefix,
            value: value ? 1 : 0,
        })];
    },

    /**
     * @param {Array} data
     * @param {String} prefix
     * @returns {Element[]}
     */
    arrayToElements(data, prefix) {
        let elements = [];
        data.forEach(function (item) {
            elements = elements.concat(FormRedirect.subToElements(item, '', prefix));
        });
        return elements;
    },

    /**
     * @param {Object} data
     * @param {String} prefix
     * @returns {Element[]}
     */
    hashToElements(data, prefix) {
        let elements = [];
        $H(data).each(function (pair) {
            elements = elements.concat(FormRedirect.subToElements(pair.value, pair.key, prefix));
        });
        return elements;
    },

    /**
     * @param {String} value
     * @param {String} key
     * @param {String} prefix
     * @returns {Element[]}
     */
    subToElements(value, key, prefix) {
        let elements = [];
        const name = prefix
            ? `${prefix}[${key}]`
            : key;
        if (typeof value === 'string' || typeof value === 'number') {
            elements = elements.concat(FormRedirect.stringToElements(value, name));
        } else if ('boolean' === typeof value) {
            elements = elements.concat(FormRedirect.booleanToElements(value, name));
        } else if (Array.isArray(value)) {
            elements = elements.concat(FormRedirect.arrayToElements(value, name));
        } else if ('function' !== typeof value) {
            elements = elements.concat(FormRedirect.hashToElements(value, name));
        }
        return elements;
    },
};
