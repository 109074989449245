// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Container } from './container';
import render from './render';

export class TwoColumns extends Container {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'two-cols-block clearfix',
            ...config,
        });
        this._columnCls = 'first-col';
    }

    _initComponentElement() {
        this._componentElement = document.createElement('div');
        this._componentElement.className = this._cls;
        this._componentElement.innerHTML = (
            `<div id="${this._contentAreaId}" class="columns-wrapper"></div>`
        );
    }

    _renderItem(item) {
        const renderTargetId = `${this._id}-item-${item.getId()}`;
        render(
            document.getElementById(this._contentAreaId),
            `<div class="${this._columnCls}"><div class="column-box" id="${renderTargetId}"></div></div>`,
        );
        render(document.getElementById(renderTargetId), item);

        this._columnCls = 'second-col';
    }
}
