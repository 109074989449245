// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { init, configureScope, Replay } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { withoutGraphQLFetch } from 'apollo-link-sentry';

export default ({ sentry }) => {
    if (!sentry) {
        return;
    }

    if (window.navigator.userAgent.match(/jsdom/)) {
        return;
    }

    const { user = null, tags = {}, environment, ...options } = sentry;
    const isDev = environment === 'development';

    init({
        integrations: [
            new Replay({
                maskAllText: !isDev,
                maskAllInputs: !isDev,
            }),
            new BrowserTracing({
                beforeNavigate: context => ({
                    ...context,
                    name: location.pathname
                        .replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[0-5][a-f0-9]{3}-[089ab][a-f0-9]{3}-[a-f0-9]{12}/ig, '/<guid>')
                        .replace(/\/[a-f0-9]{32}/g, '/<hash>')
                        .replace(/\/\d+/g, '/<digits>')
                        .replace(/\/type\/(domain|alias)/, '/type/<type>'),
                }),
            }),
        ],
        beforeSend: (event, hint) => {
            const err = hint.originalException;
            if (err && err.stack) {
                // mozilla and safari debuggers
                const catchDebugger = /global code@|@debugger/.test(err.stack);
                const stack = err.stack.split('\n');
                // chrome anonymous stack
                const anonymousCall = stack.length === 2 && /<anonymous>:1:(\d+)$/.test(stack.at(-1));
                if (catchDebugger || anonymousCall) {
                    return null;
                }
            }
            return event;
        },
        beforeBreadcrumb: withoutGraphQLFetch(breadcrumb => {
            if (breadcrumb.category === 'xhr' || breadcrumb.category === 'fetch') {
                const url = breadcrumb.data?.url ?? '';

                if (url.includes('firehose.us-west-2.amazonaws.com')) {
                    return null;
                }

                if (url.includes('/cp/locale')) {
                    const sections = new URLSearchParams(url.split('?')[1]).getAll('sections[]');

                    return {
                        type: 'http',
                        category: 'plesk.locale',
                        data: {
                            sections,
                        },
                    };
                }
            }

            return breadcrumb;
        }),
        ignoreErrors: [
            'ResizeObserver loop',
            'ApiFetchError:',
            'whmcs_user_id is not defined',
            '__renderMessageNode is not defined',
            'Module build failed',
            'jQuery is not defined',
            'safari-extension://',
            'moz-extension://',
            'chrome-extension://',
            'Object Not Found Matching Id:',
            // TODO PPP-60163: Error: Script error for ...
            'Script error for',
            // TODO EXTPLESK-4373: [Acronis Backup] TypeError: Cannot read properties of null (reading 'readAttribute')
            'loadAndDisplayNotifications',
        ],
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // TODO EXTPLESK-1671: [Help Center] Not compatible with Plesk 18.0
            '/modules/help-center/global.js',
            // TODO EXTPLESK-2342 [Speed Kit] Sentry pollution when Plesk session is expired
            '/modules/baqend/script.js',
            // TODO EXTPLESK-2242: Legacy Addendio - WordPress Plugins and Themes generates errors on login page
            '/modules/addendio-wordpress/global.js',
            // TODO EXTPLESK-2938: [ImunifyAV] TypeError: Cannot read property 'update'/'show' of null
            '/modules/revisium-antivirus/js/ra_domain.js',
            // TODO EXTWPTOOLK-10233: TypeError: null is not an object (evaluating 'body.select')
            '/modules/wp-toolkit/js/domain-page.js',
        ],
        sampleRate: isDev ? 1 : 0.05,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: isDev ? 1 : 0,
        tracesSampleRate: isDev ? 0.001 : 0,
        environment,
        ...options,
    });
    configureScope(scope => {
        scope.setUser(user);
        for (const tag in tags) {
            scope.setTag(tag, tags[tag]);
        }
    });
};
