// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    Button,
    List,
    ListEmptyView,
    SearchBar,
    Toolbar,
    ToolbarExpander,
    ToolbarGroup,
} from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { Locale } from 'jsw';
import applyFilter from '../lib/applyFilter';
import { NotificationFilterPropType, NotificationPropType } from '../lib/PropTypes';
import SubjectColumn from './SubjectColumn';
import DateColumn from './DateColumn';
import NotificationActions from '../NotificationActions';
import FilterMenu from './FilterMenu';

const Translate = Locale.getTranslate('components.notification-center');

const NotificationCenterList = ({
    notifications,
    filter = null,
    onUpdateFilter,
    onSelectNotification,
    onUpdateNotifications,
    onRemoveNotification,
    onMarkAllAsRead,
}) => {
    const notificationsToDisplay = applyFilter(notifications, filter);
    const filterCount = Object.values(filter).reduce((r, v) => r + (v ? 1 : 0), 0);
    const columns = [
        {
            key: 'subject',
            type: 'title',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <SubjectColumn
                    notification={notification}
                    onSelectNotification={onSelectNotification}
                    onUpdateNotifications={onUpdateNotifications}
                />
            ),
        },
        {
            key: 'date',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <DateColumn
                    notification={notification}
                    onSelectNotification={onSelectNotification}
                />
            ),
            width: '10%',
        },
        {
            key: 'actions',
            type: 'actions',
            width: '1%',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <NotificationActions
                    notification={notification}
                    onUpdateNotifications={onUpdateNotifications}
                    onRemoveNotification={onRemoveNotification}
                />
            ),
        },
    ];

    const toolbar = (
        <Toolbar>
            <ToolbarGroup title="">
                <Button data-type="mark-all-as-read" icon="mail" onClick={onMarkAllAsRead}>
                    <Translate content="markAllAsRead" />
                </Button>
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title="">
                <FilterMenu
                    filter={filter}
                    filterCount={filterCount}
                    onUpdateFilter={onUpdateFilter}
                />
            </ToolbarGroup>
            <ToolbarGroup title="">
                <SearchBar
                    inputProps={{ placeholder: Locale.getSection('components.notification-center').lmsg('searchPlaceholder'), value: filter.search }}
                    onTyping={value => onUpdateFilter({ search: value })}
                    minWidth={200}
                />
            </ToolbarGroup>
        </Toolbar>
    );

    return (
        <List
            rowKey="id"
            columns={columns}
            data={notificationsToDisplay}
            expandedRows={['subject']}
            toolbar={toolbar}
            emptyViewMode={filterCount ? 'items' : 'all'}
            emptyView={filterCount ? (
                <ListEmptyView
                    reason="filtered"
                    title={<Translate content="listFilteredTitle" />}
                    description={<Translate content="listFilteredDescription" />}
                />
            ) : (
                <ListEmptyView
                    title={<Translate content="listEmptyTitle" />}
                    image={require('images/notification-center-empty.svg')}
                    description={<Translate content="listEmptyDescription" />}
                />
            )}
        />
    );
};

NotificationCenterList.propTypes = {
    notifications: PropTypes.arrayOf(NotificationPropType.isRequired).isRequired,
    filter: NotificationFilterPropType,
    onUpdateNotifications: PropTypes.func.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
    onRemoveNotification: PropTypes.func.isRequired,
    onMarkAllAsRead: PropTypes.func.isRequired,
};

export default NotificationCenterList;
