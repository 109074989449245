// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const ESC = 27;
export const ENTER = 13;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;

export default {
    ESC,
    ENTER,
    LEFT_ARROW,
    UP_ARROW,
    RIGHT_ARROW,
    DOWN_ARROW,
};
