// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Component, StrictMode } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import routes from 'routes';
import { ToolbarBetaProvider } from '@plesk/ui-library';
import apolloClient from '../apolloClient';
import LocaleProvider from './LocaleProvider';

class App extends Component {
    state = {
        widgets: [],
    };

    router = createBrowserRouter(routes);

    addWidget(widget) {
        this.setState(({ widgets }) => ({
            widgets: [
                ...widgets,
                widget,
            ],
        }));
    }

    render() {
        return (
            <StrictMode>
                <ToolbarBetaProvider>
                    <ApolloProvider client={apolloClient}>
                        <LocaleProvider>
                            <RouterProvider router={this.router} />
                            {this.state.widgets}
                        </LocaleProvider>
                    </ApolloProvider>
                </ToolbarBetaProvider>
            </StrictMode>
        );
    }
}

export default App;
