// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button } from '@plesk/ui-library';
import { Locale } from 'jsw';

import HELP_MENU_QUERY from 'queries/HelpMenu.graphql';

const Translate = Locale.getTranslate('components.shortcuts');
const HelpDrawer = lazy(() => import(/* webpackChunkName: "components/layouts/page-layout/help-drawer" */'./HelpDrawer'));

const HelpMenu = ({
    helpUrl = undefined,
    customHelpUrl = undefined,
    helpContext = undefined,
    helpModule = undefined,
    searchQuery = undefined,
}) => {
    const { data: { viewer: user, mode, config } = {} } = useQuery(HELP_MENU_QUERY);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpened, setIsOpened] = useState(isOpen);

    const handleOpen = () => {
        setIsOpen(true);
        setIsOpened(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if (helpUrl && !mode?.isClassicPanel) {
        if (helpContext) {
            helpUrl += `/plesk-context/${helpContext}`;
        }
        if (helpModule) {
            helpUrl += `/plesk-module/${helpModule}`;
        }
    }

    if (!customHelpUrl && !helpUrl) {
        helpUrl = `/help.php?context=${encodeURIComponent(helpContext)}`;
        if (helpModule) {
            helpUrl += `&module=${encodeURIComponent(helpModule)}`;
        }
    }

    if (user?.type === 'ADMIN') {
        return (
            <>
                <Button
                    id="help-menu"
                    data-help-context={helpContext}
                    data-help-prefix={user?.type}
                    data-help-module={helpModule}
                    ghost
                    icon="question-mark-circle"
                    onClick={handleOpen}
                />
                {isOpened ? (
                    <Suspense>
                        <HelpDrawer
                            helpUrl={customHelpUrl || helpUrl}
                            searchQuery={(config.help.enableSearchPrefill && searchQuery) || ''}
                            isOpen={isOpen}
                            onClose={handleClose}
                        />
                    </Suspense>
                ) : null}
            </>
        );
    }

    return (
        <Button
            id="help-menu"
            ghost
            icon="question-mark-circle"
            data-type="link-read-manual"
            data-help-context={helpContext}
            data-help-prefix={user?.type}
            data-help-module={helpModule}
            component="a"
            href={customHelpUrl || helpUrl}
            target="_blank"
            rel="noopener noreferrer"
            tooltip={<Translate content="readManual" />}
        />
    );
};

HelpMenu.propTypes = {
    helpUrl: PropTypes.string,
    customHelpUrl: PropTypes.string,
    helpContext: PropTypes.string,
    helpModule: PropTypes.string,
    searchQuery: PropTypes.string,
};

export default HelpMenu;
